.sectionTitle {
    font-size: 1.5rem;
    font-weight: 500;
    margin-bottom: 1rem;
    color: #222222;
    position: relative;
    padding-bottom: 0.5rem;
}

.sectionTitle:after {
    content: "";
    position: absolute;
    width: 40px;
    height: 3px;
    background: #34b7a7;
    bottom: 0;
    left: 0;
}
