.experienceCard {
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    border-radius: 8px;
    overflow: hidden;
    height: 100%;
    border: 1px solid rgba(0, 0, 0, 0.1);
    margin-bottom: 16px;
    background-color: #fff;
}

.experienceCard:hover {
    transform: translateY(-5px);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
}

.cardTitle {
    color: #34b7a7;
    font-weight: 600;
    margin: 0;
    font-size: 1.25rem;
}

.durationBadge {
    background-color: #007bff;
    color: white;
    font-weight: 500;
    padding: 0.35em 0.8em;
    font-size: 0.8rem;
}

.iconMuted {
    color: #6c757d;
    font-size: 0.9rem;
}

/* Company Logo Styles */
.companyLogo {
    width: 30px;
    height: 30px;
    object-fit: contain;
    margin-right: 10px;
    border-radius: 4px;
    background-color: #f8f9fa;
    border: 1px solid rgba(0, 0, 0, 0.1);
    padding: 2px;
}

.tagsContainer {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-top: 0.5rem;
}

.moreBadge {
    background-color: #e9ecef;
    color: #495057;
    font-size: 0.75rem;
}

.cardFooter {
    text-align: center;
    padding: 0.5rem;
    background-color: rgba(0, 0, 0, 0.03);
    border-top: 1px solid rgba(0, 0, 0, 0.125);
    color: #6c757d;
    font-size: 0.85rem;
}
