.skillBadge {
    font-weight: normal;
    font-size: 0.8rem;
    padding: 0.3rem 0.6rem;
    border-radius: 4px;
    margin-right: 0.4rem;
    margin-bottom: 0.4rem;
}

.techBadge {
    background-color: #e9f9f7;
    color: #444;
    font-weight: 400;
    padding: 0.3rem 0.6rem;
    margin-right: 0.5rem;
    margin-bottom: 0.25rem;
    border-radius: 4px;
    display: inline-block;
    font-size: 0.85rem;
}

.softBadge {
    font-weight: normal;
    font-size: 0.8rem;
    padding: 0.3rem 0.6rem;
    border-radius: 4px;
    background-color: #f8f9fa;
    color: #495057;
}

.filterBadge {
    font-weight: normal;
    font-size: 0.8rem;
    padding: 0.3rem 0.6rem;
    border-radius: 4px;
    margin-right: 0.4rem;
    margin-bottom: 0.4rem;
    border: 1px solid rgba(0, 0, 0, 0.1);
}

.selectable {
    cursor: pointer;
    transition: all 0.2s ease;
}

.selectable:hover {
    background-color: #e9ecef;
}

.selected {
    background-color: #34b7a7 !important;
    color: white !important;
}

.prefix {
    opacity: 0.7;
    margin-right: 0.2rem;
}
