.summaryCard {
    transition: all 0.3s ease;
    border-radius: 8px;
    overflow: hidden;
    margin-bottom: 1.5rem;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
    border: none;
}

.summaryCard:hover {
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.08);
}

.cardHeader {
    background-color: #f8f9fa;
    border-bottom: 2px solid #34b7a7;
    padding: 0.8rem 1rem;
}

.cardHeader h4 {
    margin: 0;
    font-size: 1rem;
    font-weight: 600;
    color: #34b7a7;
}

.icon {
    color: #34b7a7;
    margin-right: 0.5rem;
}

.clearButton {
    cursor: pointer;
    font-size: 0.7rem;
    padding: 0.25rem 0.5rem;
    transition: all 0.2s ease;
}

.clearButton:hover {
    background-color: #5a6268;
}

/* Tag Section Styling */
.tagSection {
    padding: 0.5rem 0;
    border-bottom: 1px solid #f0f0f0;
    transition: background-color 0.2s ease;
}

.tagSection:last-child {
    border-bottom: none;
}

.activeSection {
    background-color: #f8fffd;
}

.tagSectionHeader {
    padding: 0.2rem 1rem;
    font-size: 0.75rem;
    font-weight: 600;
    color: #6c757d;
    text-transform: uppercase;
    letter-spacing: 0.5px;
}

.tagContainer {
    display: flex;
    flex-wrap: wrap;
    padding: 0 0.7rem 0.5rem;
    gap: 0.4rem;
}
