.modalDialog {
    max-width: 700px;
}

.modalHeader {
    background-color: #f8f9fa;
    border-bottom: 3px solid #34b7a7;
    padding: 1.25rem 1.5rem;
}

.modalTitle {
    width: 100%;
}

.modalJobTitle {
    color: #34b7a7;
    font-weight: 600;
    margin-bottom: 0;
    font-size: 1.5rem;
}

.modalSubtitle {
    display: flex;
    align-items: center;
    color: #6c757d;
    font-size: 0.95rem;
}

.modalIcon {
    color: #34b7a7;
    margin-right: 8px;
}

.modalBody {
    padding: 1.5rem;
}

.jobDescription {
    font-size: 1rem;
    line-height: 1.6;
    color: #343a40;
    margin-bottom: 1.5rem;
}

.sectionTitle {
    color: #34b7a7;
    font-weight: 600;
    margin: 1.5rem 0 1rem;
    font-size: 1.25rem;
    display: flex;
    align-items: center;
}

.achievementsList {
    margin-bottom: 2rem;
}

.achievementItem {
    display: flex;
    padding: 0.75rem 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

.achievementItem:last-child {
    border-bottom: none;
}

.checkCircle {
    color: #34b7a7;
    margin-right: 12px;
    min-width: 20px;
}

.achievementText {
    flex: 1;
    line-height: 1.5;
}

.techTagsContainer {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
}

.techTag {
    background-color: #007bff;
    color: white;
    font-weight: 400;
    padding: 8px 12px;
    border-radius: 4px;
    font-size: 0.85rem;
}

.modalFooter {
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    padding: 1rem 1.5rem;
}

.closeButton {
    background-color: #6c757d;
    border: none;
    padding: 0.5rem 1.5rem;
}

.closeButton:hover {
    background-color: #5a6268;
}

.modalLogo {
    width: 60px;
    height: 60px;
    object-fit: contain;
    margin-right: 16px;
    border-radius: 6px;
    background-color: #f8f9fa;
    padding: 4px;
    border: 1px solid rgba(0, 0, 0, 0.1);
}
