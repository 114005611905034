.cardEnter {
  opacity: 0;
  transform: scale(0.9) translateY(20px);
}

.cardEnterActive {
  opacity: 1;
  transform: scale(1) translateY(0);
  transition: opacity 500ms ease-in-out, transform 500ms ease-in-out;
}

.cardExit {
  opacity: 1;
  transform: scale(1) translateY(0);
}

.cardExitActive {
  opacity: 0;
  transform: scale(0.9) translateY(20px);
  transition: opacity 500ms ease-in-out, transform 500ms ease-in-out;
}

/* No results styles */
.noResults {
  width: 100%;
  text-align: center;
  padding: 3rem 1rem;
  background-color: #f8f9fa;
  border-radius: 8px;
  border: 1px dashed #dee2e6;
  margin-top: 1rem;
}

.noResults p:first-child {
  font-size: 1.25rem;
  font-weight: 500;
  color: #6c757d;
  margin-bottom: 0.5rem;
}

.noResults p:last-child {
  color: #adb5bd;
  font-size: 0.9rem;
}
