.summaryCard {
    transition: all 0.3s ease;
    border-radius: 8px;
    overflow: hidden;
    margin-bottom: 1.5rem;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
    border: none;
}

.summaryCard:hover {
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.08);
}

.cardHeader {
    background-color: #f8f9fa;
    border-bottom: 2px solid #34b7a7;
    padding: 0.8rem 1rem;
}

.cardHeader h4 {
    margin: 0;
    font-size: 1rem;
    font-weight: 600;
    color: #34b7a7;
}

.icon {
    color: #34b7a7;
    margin-right: 0.5rem;
}

/* List Items Styling */
.listItem {
    padding: 0.5rem 1rem;
    display: flex;
    align-items: center;
    font-size: 0.875rem;
    border: none;
    transition: background-color 0.2s ease;
}

.listItem:hover {
    background-color: #f9f9f9;
}

.listItemIcon {
    color: #34b7a7;
    margin-right: 0.75rem;
    font-size: 0.875rem;
}

/* Section Dividers */
.sectionDivider {
    background-color: #f8f9fa;
    padding: 0.5rem 1rem;
    border-top: 1px solid #e9ecef;
    border-bottom: 1px solid #e9ecef;
}

.dividerTitle {
    font-size: 0.75rem;
    font-weight: 600;
    color: #6c757d;
    text-transform: uppercase;
    letter-spacing: 0.5px;
}

/* Soft Skills */
.softSkillsContainer {
    display: flex;
    flex-wrap: wrap;
    padding: 0.75rem 1rem;
    gap: 0.4rem;
}

/* Education */
.educationSection {
    padding: 0.75rem 1rem 1rem;
}

.educationSection h5 {
    font-size: 1rem;
    font-weight: 600;
    color: #34b7a7;
    margin-bottom: 0.5rem;
}

.educationBadge {
    background-color: #34b7a7;
    padding: 0.3rem 0.5rem;
    font-size: 0.75rem;
    font-weight: 500;
    border-radius: 4px;
}

.educationLink {
    display: block;
    margin-top: 0.5rem;
    font-size: 0.875rem;
    color: #34b7a7;
    text-decoration: none;
    transition: color 0.2s ease;
}

.educationLink:hover {
    color: #2a9286;
    text-decoration: underline;
}
